<template>
  <div class="container">
    <div class="stocks__title title">{{ stock.name }}</div>
    <div class="stocks__text text">
      <div class="typography" v-html="stock.previewText"></div>
    </div>
    <div class="stocks-cover">
      <div class="stocks-cover__img">
        <img :src="stock.picture" alt="" />
      </div>
      <div class="stocks-cover__caption">{{ stock.activeFrom }}</div>
    </div>
    <div class="stocks-description text">
      <div class="typography" v-html="stock.detailText"></div>
    </div>

    <div class="stocks-gallery" v-if="stock.slider">
      <div class="stocks-gallery__title title title--second">Фотогалерея</div>

      <!-- <Slider
        :sliders="stock.slider"
        :swiperOptions="swiperOptions"
        :classItem="'stocks-gallery__slider'"
      /> -->
    </div>

    <div class="stocks-suggestions">
      <div class="stocks-suggestions__title title title--second">
        Товары по акции
      </div>
      <div class="stocks-suggestions__cards">
        <div
          class="stocks-suggestions__card"
          v-for="product in stock.linkGoods"
          :key="product.id"
        >
          <Product :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Slider from "@/components/slider";
import Product from "@/components/cards/product";
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("STOCKS_FETCH_ITEM_DATA", to.params.code).then(() => {
      next();
    });
  },

  components: {
    Product,
  },

  data() {
    return {
      swiperOptions: {
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
      },
    };
  },

  computed: {
    stock() {
      return this.$store.state.stocks.selectStock;
    },
  },
};
</script>
